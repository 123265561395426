import React, { 
  // useCallback, 
  // useState, 
  // useEffect, 
  // useRef 
} from "react";
import { useNavigate } from 'react-router-dom';
import Login from './login'
import { Page} from "@shopify/polaris";

export default function Home(props){
  console.log('Home.js', props)
  useNavigate();

  return (
    <Page fullWidth={true}>
      <div className="layout-home">
        <div className="layout-login">
        <Login />
        </div>
        <div className='home-bg' style={{
          // backgroundImage: 'url(/illustration-of-warehouse-2.jpg)',
          // backgroundImage: 'url(/illustration-of-warehouse-4.webp)',
          // backgroundImage: 'url(/illustration-of-warehouse-3.webp)',
          backgroundImage: 'url("https://3pllinks.com/wp-content/uploads/2022/09/empty-warehouse-storage-distribution-centers_41470-4881.jpg")'
          
          // backgroundSize: 'cover',
          // width: 'unset',
          // height: '96vh',
          // marginLeft: '10%'
        }}>
          <div style={{
            backgroundImage: 'url(logo-big-1.png)',
            width: '100%',
            height: '100%',
            backgroundPosition: '100% 100%',
            backgroundSize: '30%',
            backgroundRepeat: 'no-repeat'         
          }}></div>
        </div>
      </div>
    </Page>
  )
}


/*
      <EmptyState
        heading="Manage your Integrated Express Professional Work"
        action={{content: 'Login', onAction:()=>{ navigate('/login'); }}}
        image="https://cdn.shopifycdn.net/s/files/1/0507/2345/5144/files/express-bg.jpg?v=1636463220"
        // image="https://burst.shopifycdn.com/photos/orange-sports-car-close-up.jpg?width=1200&format=pjpg&exif=0&iptc=0"
      >
        <p>Use the most favorable express account to send packages</p>

      </EmptyState>
*/