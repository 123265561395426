
import cookie from 'react-cookies'
import moment from 'moment';
// timezone='UTC'  timezone:moment().format('Z')

// const HOST_URL  = "development" === process.env.NODE_ENV ? 'https://whpicker-dev.jcbdx.net:8887/whapi' : 'https://shipapi.tirewheelconnect.com';
// const HOST_URL_R_Image  =  "development" === process.env.NODE_ENV ? 'https://inv.acuinsight.com:9529/files' : 'https://inv.acuinsight.com:9529/files';
// const Timeline_Files  =  "development" === process.env.NODE_ENV ? 'https://inv.acuinsight.com:9529/files' : 'https://inv.acuinsight.com:9529/files';

// const HOST_URL = window && window.AppGlobal.Hosts.HOST_URL
// const HOST_URL_R_Image = window && window.AppGlobal.Hosts.HOST_URL_R_Image
// const Timeline_Files = window && window.AppGlobal.Hosts.Timeline_Files

// function customFetch(){
//   const _token = cookie.load('token');
//   const HOST_URL = 'https://integrated-express.jcbdx.net';
//   const url = HOST_URL + arguments[0];
//   const options = Object.assign({}, arguments[1]);
//   options.headers = Object.assign({ 'Content-Type': 'application/json' }, options.headers);

//   if (typeof options.body == "object") {
//     options.body = JSON.stringify({...options.body, _token});
//   }

//   console.log('customFetch', options)
//   return fetch(url, options);

// }

async function customFetchUpload_FileServer_TimeLine(){
  const _token = cookie.load('token');
  const url = window.AppGlobal.Hosts.Timeline_Files + arguments[0];
  if(arguments[1]['headers']){
    arguments[1]['headers']['token'] = _token;
  } 
  console.log(arguments[1])
  const response = await fetch(url, arguments[1]);
  console.log(response)
  const resdata = await response.json();  
  return resdata;
}

async function customFetchUpload_FileServer_R_image(){
  const _token = cookie.load('token');
  const url = window.AppGlobal.Hosts.HOST_URL_R_Image + arguments[0];
  if(arguments[1]['headers']){
    arguments[1]['headers']['token'] = _token;
  } 
  // console.log(arguments[1])
  const response = await fetch(url, arguments[1]);
  // console.log(response)
  if(200 === response.status){
    const resdata = await response.json();  
    return resdata;
  }else{
    return response;
  }
  
}

async function customFetchUpload(){
  const _token = cookie.load('token');
  const url = window.AppGlobal.Hosts.HOST_URL + arguments[0];
  // console.log(arguments[1])
  if(arguments[1]['headers']){
    arguments[1]['headers']['token'] = _token;
  } 
  const response = await fetch(url, arguments[1]);
  const resdata = await response.json();  
  return resdata;
}



function customfetchDownload() {
  const _token = cookie.load('token');
  const url = window.AppGlobal.Hosts.HOST_URL + arguments[0];
  const options = Object.assign({}, arguments[1]);
  if(arguments[1]['headers']){
    arguments[1]['headers']['_token'] = _token;
  }
  options.headers = Object.assign({ 'Content-Type': 'application/json', "_token":_token }, options.headers);
  if (typeof options.body === "object") {
    options.body = JSON.stringify({...options.body, _token});
  }  
  return fetch(url, options)
}



async function customFetchRimageSever(){
  const _token = cookie.load('token');
  const url = window.AppGlobal.Hosts.HOST_URL_R_Image + arguments[0];
  const options = Object.assign({}, arguments[1]);
  options.headers = Object.assign({ 'Content-Type': 'application/json' }, options.headers);
  if (typeof options.body == "object") {
    options.body = JSON.stringify({...options.body, _token, timezone:moment().format('Z')});
  }
  const response = await fetch(url, options);
  const resdata = await response.json();
  return resdata;

}

async function customFetch(){
  const _token = cookie.load('token');
  // const HOST_URL = 'https://integrated-express.jcbdx.net';
  const url = window.AppGlobal.Hosts.HOST_URL + arguments[0];
  const options = Object.assign({  
    // credentials: 'include', 
    // mode: 'no-cors' 
    // mode: 'cors',
    // credentials: 'include'
  }, arguments[1]);
  options.headers = Object.assign({ 'Content-Type': 'application/json' }, options.headers);

// console.log('######', options.headers)


  if (typeof options.body == "object") {
    options.body = JSON.stringify({...options.body, _token, timezone:moment().format('Z')});
  }

  // console.log('customFetch', options)
  const response = await fetch(url, options);
  const resdata = await response.json();
  // console.log( resdata )
  if(resdata.error){
    // const _authfail = cookie.load('authfail');
    const _t_ = new Date().getTime();
    // if(_authfail){
      
    // }else{
    //   cookie.save('authfail', `${_t_}`, { path: '/' });
    //   window.location.reload();
    // }
    cookie.remove('token', { path: '/' });
    cookie.remove('userInfo', { path: '/' })
    cookie.save('authfail', `${_t_}`, { path: '/' });
    window.location.reload();

  }else{
    return resdata;
  }

}

// let customFetch = deBounce(customFetch1111, 1000)


export {
  customFetch,
  customFetchUpload,
  customfetchDownload,
  customFetchUpload_FileServer_TimeLine,
  customFetchUpload_FileServer_R_image,
  customFetchRimageSever
}

// function deBounce(func, wait) {
//   let timeOut = null;
//   return function (...args) {
//     clearTimeout(timeOut);//一定要清除定时器
//     timeOut = setTimeout(() => {
//       func(...args);
//     }, wait);
//   };
// }



// const request = require("request");

// export default function rp() {
//   // const { publicRuntimeConfig } = getConfig();
//   const HOST_URL = 'https://integrated-express.jcbdx.net'; // publicRuntimeConfig.ADMIN_API_URL;
//   const APPNAME = ''; // publicRuntimeConfig.APPNAME;
//   const VERSION = ''; // publicRuntimeConfig.VERSION;
//   // console.log("Tools request publicRuntimeConfig", publicRuntimeConfig);
//   let shop = null;
//   const options = Object.assign({ strictSSL: false }, arguments[0]);
//   options.url = `${HOST_URL}${options.url}`;
//   // console.log(options);
//   // console.log("Tools request options: ", options);

//   if (options.url.indexOf("shop=") <= -1 && shop) {
//     if (options.url.indexOf("?") > -1) {
//       options.url =
//         options.url + `&shop=${shop}&app=${APPNAME}&version=${VERSION}`;
//     } else {
//       options.url =
//         options.url + `?shop=${shop}&app=${APPNAME}&version=${VERSION}`;
//     }
//   }
//   if (typeof options.body == "object") {
//     options.body["app"] = APPNAME;
//     options.body["version"] = VERSION;
//   }

//   if (typeof options.body == "object" && !options.body["shop"]) {
//     options.body["shop"] = shop;
//   }

//   console.log("Tools request setend: ", shop, JSON.stringify(options));

//   request(options, (err, httpResponse, body) => {
//     if(typeof arguments[1] == 'function') arguments[1](err, httpResponse, body);
//   });
// }


