import cookie from 'react-cookies'
import React, { useState, useEffect } from 'react';

import {
  Route,
  Routes,
  useNavigate
} from 'react-router-dom';

// import translations from "@shopify/polaris/locales/en.json";
// import translations from "@shopify/polaris/locales/zh-CN.json";

import translations from "@shopify/polaris/locales/en.json";

// import { I18nContext, I18nManager} from '@shopify/react-i18n';

import RouterMap from './routeMap'
import NotFoundComponent from './components/notFound'
import Home from './home'


import {AppProvider, Modal, List, Card} from "@shopify/polaris"; 
import moment from "moment-timezone"




// console.log('moment', moment.unix(1637557718).format('LLL'))

String.prototype.toUpperCaseFirstChar = function(){
  return this && (''+this)[0].toUpperCase()+(''+this).split('').slice(1).join('')
}

String.prototype.dateFormatZone = Number.prototype.dateFormatZone = function(Token){
  return moment.unix(this * 1).format(Token ? Token : 'll LTS')
}

String.prototype.fromNow = function(){
  try {
    let _value = this;
    let that = this;
    if(!isNaN(this)) that = 1* this;

    let _diff = moment().diff(moment(that), 'days')
    if( _diff < 1 ) {
      _value = moment(that).fromNow() 
    }else if(_diff > 365){
      _value = moment(that).format('LLL')
    }else {
      _value = moment(that).format('MMM D, LT')
    }
    return _value
  } catch (error) {
    console.log('prototype.fromNow  err', error)
  }
  return this;
}

String.prototype.formatFileSize = Number.prototype.formatFileSize = function() {
  const fileSize = this * 1;

  if (fileSize < 1024) {
    return fileSize + 'B';
  } else if (fileSize < (1024*1024)) {
    let temp = fileSize / 1024;
    temp = temp.toFixed(2);
    return temp + 'KB';
  } else if (fileSize < (1024*1024*1024)) {
    let temp = fileSize / (1024*1024);
    temp = temp.toFixed(2);
    return temp + 'MB';
  } else {
    let temp = fileSize / (1024*1024*1024);
    temp = temp.toFixed(2);
    return temp + 'GB';
  }
}

String.prototype.toThousands = Number.prototype.toThousands = function(symbol='$' ,unit='USD') {
  let num = (this * 1 ).toString();
  let [integer,decimal='00'] = num.split('.');
  let negative = integer * 1 < 0 ? '-' :'';
  integer = (Math.abs(integer) || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  return `${unit}${unit.length>0?' ':''}${negative}${symbol}${integer}.${decimal.slice(0,2)}`;
}


export default function AppProv(props){
  // i18n.translations is an array of translation dictionaries, where the first
  // dictionary is the desired language, and the second is the fallback.
  // const i18nManager = new I18nManager({
  //   locale:'en',
  //   id: 'Polaris',
  //   fallback: translations,
  //   translations(locale) {
  //     return import(
  //       /* webpackChunkName: "Polaris-i18n", webpackMode: "lazy-once" */ `@shopify/polaris/locales/${locale}.json`
  //     ).then((dictionary) => dictionary && dictionary.default);
  //   },
  // });

  return (
    <AppProvider i18n={translations}>
      {/* <I18nContext.Provider value={i18nManager}> */}
      {/* <ThemeProvider theme={theme}> */}
      <App token={props.token} userInfo={props.userInfo} />
      {/* </ThemeProvider> */}
      {/* </I18nContext.Provider> */}
    </AppProvider>  
  )
}



function App(props) { 
  const navigate = useNavigate();
  const [token, setToken] = useState(props.token);
  const [userInfo, setUserInfo] = useState(props.userInfo)
  const [authFail, setAuthFail] = useState(false);
  const [defaultURL, setDefaultURL] = useState('/');
  useEffect(() =>{},[])

  useEffect(() => {     
    // console.log('------------- loadToken ------------- , defaultURL:%o', defaultURL)
    const _token = cookie.load('token');
    const _userInfo = cookie.load('userinfo');
    const _authfail = cookie.load('authfail');
    // console.log( '_authfail', _authfail );
    if(_authfail){
      setAuthFail(true);
    }else{
      if (_token && _token.length > 5) {
        setUserInfo(_userInfo);
        setToken(_token);
        // if(window.location.pathname === '/' ) navigate('/admin/dashboards');
        // console.log('window.location',window.location.pathname)
        // console.log('AppGlobal' , window.AppGlobal)
        if(window.AppGlobal) window.AppGlobal.userInfo = _userInfo;
        if(window.location.pathname === '/' && defaultURL!== '/' ) navigate({pathname:defaultURL});
      }else{
        if(window.location.pathname !== '/' ) navigate({pathname:'/'});
      }
    }

  },[navigate, defaultURL]);

  return token ? (
    authFail ? ( <ModalAuthFail active={authFail} onClose={()=>{
      // console.log('authFail', authFail, 'onClose')
      cookie.remove('token', { path: '/' });
      cookie.remove('userInfo', { path: '/' })
      cookie.remove('authfail', { path: '/' })
      window.location.reload();
    }} /> ) : (
    <RouterMap 
      token={token} 
      userInfo={userInfo} 
      onReady={(val)=>{
        let _defaultURL = '/'
        if(val && val[0] && val[0]['section']){
          if( val[0].items && val[0].items.length > 0 ){
            _defaultURL = val[0].items[0].url
          }
        }else{
          _defaultURL = val[0].url
        }
        setDefaultURL(_defaultURL);
      }}
    />) 
  ) : (
    <Routes>
      <Route path="/" element={<Home />} />
      {/* <Route path="/login" element={<Login onLogin={loginToken} />} /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

function NotFound() {
  window.location.href='/'

  return (
    <NotFoundComponent />
  )
}


function ModalAuthFail(props) {
  return (
      <Modal
        open={props.active}
        onClose={props.onClose}
        title="Account exception Please log in again."
        primaryAction={{
          content: 'Re Login',
          onAction: props.onClose,
          destructive: true
        }}
      >
        <Modal.Section>
          <Card title="The reasons for seeing this pop-up window may be as follows" sectioned subdued>
            <List>
              <List.Item>Network address fluctuates</List.Item>
              <List.Item>The account has been logged in elsewhere</List.Item>
              <List.Item>Login hold time is too long</List.Item>
            </List>
          </Card>
        </Modal.Section>
      </Modal>
  );
}