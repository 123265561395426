
import { Page, /*TextContainer, Text, VerticalStack, HorizontalStack, Thumbnail, LegacyCard,*/ EmptyState } from "@shopify/polaris";

export default function NotFound() {
  return (
    <Page fullWidth={true}>
      <EmptyState
        heading="There's no page at this address"
        action={{content: 'History Back', onAction:()=>{window.history.back()}}}
        image="https://cdn.shopify.com/shopifycloud/web/assets/v1/f9352e9573adbafe.svg"
        fullWidth
      >
        <p>
          Check the URL and try again, or use the search bar to find what you need.
        </p>
      </EmptyState>
    </Page>
  )
  // return(
  //   <Page fullWidth={true}>
  //     <VerticalStack align="center">
  //       <HorizontalStack>
  //         <Thumbnail size="large" source="https://cdn.shopify.com/shopifycloud/web/assets/v1/f9352e9573adbafe.svg"></Thumbnail>
  //         <VerticalStack>
  //           <Text>There's no page at this address</Text>
  //           <Text>Check the URL and try again, or use the search bar to find what you need.</Text>
  //         </VerticalStack>
  //       </HorizontalStack>
  //     </VerticalStack>
  //   </Page>
  // )
}

// export default function notFound() {
//   // window.location.href='/'
//   const style_bg = {
//     width:'100%', 
//     height:'60vh',
//     background: 'url(https://cdn.shopify.com/shopifycloud/partners/assets/admin/empty-states-fresh/partners-generic-df54927807fc27d4c5cab379ebfed287002aefd3a468b6f6fd1d6ee86a70086d.svg)',
//     backgroundRepeat: 'no-repeat'
//   }
//   return (
//     <Page fullWidth={false}>
//       <div style={style_bg}>
//         <div style={{position: 'absolute', top: '10%',maxWidth: '50%'}}>
//           <VerticalStack>
//             <Text variant="medium">The page you’re looking for could not be found</Text>
//             <Text variant="small">
//               <Text color="subdued">Please make sure the web address is correct.</Text>
//             </Text>
//           </VerticalStack>
//         </div>
//       </div>
//     </Page>
//   )
// }

