import Loadable from 'react-loadable';
// import {HomeMajor, OrdersMajor } from "@shopify/polaris-icons";
import * as PolarisIcons from "@shopify/polaris-icons";

import React, { useState, useEffect, useMemo } from 'react';
import {
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import CLayout from './layout';
import NotFound from './components/notFound'
import { customFetch } from './tools/request';

import {
  SkeletonPage,
  Layout,
  Card,
  VerticalStack,  
  SkeletonDisplayText,
  SkeletonBodyText,
  Loading
} from '@shopify/polaris';

// const RouterList1 = [
//   { url: "/admin/home", icon: OrdersMajor, label: "Home", component: LoadableComponent({ loader: () => import('./home') })  },
//   { url: "/admin/dashboards", icon: HomeMajor, label: "Dashboards", component: LoadableComponent({ loader: () => import('./pages/dashboards') }) ,  auth: true  },
//   { url: "/admin/account/:id", icon: HomeMajor, label: "Account", component: LoadableComponent({ loader: () => import('./pages/account') }) ,navigateHidden:true ,  auth: true  },
//   { url: "/admin/invoice/:id", icon: HomeMajor, label: "Invoice", component: LoadableComponent({ loader: () => import('./pages/invoice') }) ,navigateHidden:true ,  auth: true  }
// ]

function LoadableComponent(opts) {
  return Loadable(Object.assign({
    loading: Loading,
    delay: 200,
    timeout: 1000,
  }, opts));
};

// function Loading() {
//   return <Loading />;
// }

Loadable({
    loader: () => import('./home'),
    loading: <Loading />
})

function DynamicComponent(props) {
  const Render = props.component
  if (props.token && props.token.length > 5) {
    return (<Render />)
  } else {
    return (
      <Navigate to={{ pathname: '/', state: { from: props.location } }} />
    )
  }

}

// function NotFound() {
//   return (
//     <p> NotFound </p>
//   )
// }

// export default routeMap;
export default function RouteMap(props) {
  const {category, username='-'} = props.userInfo
  
  // console.log('RouteMap props:%o', props)
  const [loading, setLoading] = useState(true);

  const [routerList, setRouterList] = useState([]);
  const [routerSection, setRouterSection] = useState([]);

  const navList = useMemo(()=>{
    if(routerList.length>0  && routerSection.length >0){
      let _routelistJson = {};
      let _routerSection = routerSection.map(item=>{ return {section:item.section} });
      routerList.filter(showNav).forEach(item=>{
        // console.log(item.url, window.location.pathname, window.location.pathname.indexOf(item.url))
        let label = item.label
        let badge = null
        if('super' === category ){
          if(item.url.split('/')[1] === 'admin' ){
            label = item.url.split('/')[2].split('-')?.map(item=>item[0].toUpperCase()+item.slice(1)).join(' ') 
            badge = 'admin'
          }else{
            label = item.url.split('/')[1].split('-')?.map(item=>item[0].toUpperCase()+item.slice(1)).join(' ')
            badge = 'employe'
          }
        }

        let _routeitem = { 
          badge: badge,
          url: item.url,
          label: label || item.label ,
          selected: window.location.pathname.indexOf(item.url) > -1 //item.url === window.location.pathname
        }

        if("0" === item.parentId){
          _routelistJson[item.id] = {
            ..._routeitem,
            icon: item.icon,
            section:item.section,  
            subNavigationItems:[]
          }
        }else{
          if(_routelistJson[item.parentId]){
            _routelistJson[item.parentId]['subNavigationItems'].push({..._routeitem, disabled:false})
          }
        }

      })

      Object.keys(_routelistJson).forEach(key=>{ 
        let item = _routelistJson[key]
        _routerSection.forEach((data)=>{
          if(data.section === item.section){
            // data.title = data.section;
            if(!data['items']) { data['items']=[] }
            data['items'].push(item)
          }
        })
      })
  
      // console.log('_routerSection', _routerSection)
      return _routerSection;
      
    }else{
      return [];
    }

  },[category, routerList, routerSection])

  useEffect(()=>{
    async function query(){
      console.log('+++++++++++  window.AppGlobal',  window.AppGlobal)
      setLoading(true);
      if(window.AppGlobal){
        window.AppGlobal.pagePath = {}
        // window.AppGlobal.func.getCategoryPagePath = (category)=>{
        //   console.log('getCategoryPagePath', category, window.AppGlobal.pagePath[category])
        //   try {
        //     let _url = window.AppGlobal.pagePath[category];
        //     _url = _url.split(':')[0];
        //     return _url;
        //   } catch (error) {
        //     console.log('getCategoryPagePath', error)
        //     return '#'
        //   }

        // }
      }
      const data = await customFetch('/management/index/getRoutemap', {
        method: 'POST',
        body: {username: username}
      });

      let _routelist = data[0]?.map(item=>{
        if(item.category) window.AppGlobal.pagePath[item.category] = item.url;
        return{
          id:item.id,
          parentId:item.parentId,
          url: item.url, 
          icon: PolarisIcons[item.icon], 
          label: item.label, 
          component: LoadableComponent({ loader: () => import(`${item.component}`) }) ,
          navigateHidden: item.navigateHidden>0 ? true : false ,  
          auth: item.auth>0 ? true : false,
          section:item.section
        }
      })
      // console.log('_routelist', _routelist)
      setRouterSection(data[1])
      setRouterList(_routelist)
      setLoading(false);
    }

    query();
  },[username])

  useEffect(()=>{
    if(props.onReady && navList.length > 0  ) props.onReady(navList)
  },[navList, props])

  return loading ? (<LoadingPage />)  : (
    <CLayout token={props.token} userInfo={props.userInfo} routeMap={navList} children=
    {(
      <Routes>
        {routerList.map((item, index) => {
          // console.log('-------', item, index)
          return <Route key={item.id} path={item.url} element={<DynamicComponent {...item} token={props.token} userInfo={props.userInfo} />} />
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
      )} 
    />
  )

  function showNav(item) {
    // console.log('###', item.navigateHidden, item)
    return item.navigateHidden === false || item.navigateHidden === undefined || item.navigateHidden === null
  }
}

function LoadingPage(){
  return (
    <SkeletonPage primaryAction secondaryActions={2}>
      <Layout>

        <Layout.Section>
          <VerticalStack gap="4">
            <Card background="bg-subdued">
              <SkeletonBodyText />
            </Card>
            <Card background="bg-subdued">
              <VerticalStack gap="4">
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText/>
              </VerticalStack>
            </Card>            
          </VerticalStack>
        </Layout.Section>

        <Layout.Section secondary>
          <VerticalStack gap="4">
            <Card background="bg-subdued">
              <SkeletonBodyText />
            </Card>
            <Card background="bg-subdued">
              <VerticalStack gap="4">
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText/>
              </VerticalStack>
            </Card>            
          </VerticalStack>
        </Layout.Section>

      </Layout>
    </SkeletonPage>
  )
}